<script setup>
import { reactive, computed } from "vue";
import { Link } from "@inertiajs/vue3";

const props = defineProps({
    type: {
        type: String,
        default: "button",
        required: false,
    },
    kind: {
        type: String,
        default: "primary",
    },
    loading: {
        type: Boolean,
        default: false,
        required: false,
    },
    disabled: {
        type: Boolean,
        default: false,
        required: false,
    },
    href: {
        type: String,
        default: null,
        required: false,
    },
    class: {
        type: String,
        default: "",
        required: false,
    },
    method: {
        type: String,
        default: "get",
        required: false,
    },
});
const generatedKind = computed(function () {
    return (
        "v-btn btn btn-" +
        props.kind +
        " " +
        props.kind +
        " " +
        props.class +
        " transition"
    );
});
</script>

<template>
    <template v-if="props.type == 'download' && props.href != null">
        <a :href="href" :class="generatedKind" v-bind="$attrs"><slot></slot></a>
    </template>
    <template v-else>
        <Link
            :href="href"
            v-if="href != null"
            :class="generatedKind"
            v-bind="$attrs"
            :method="method"
        >
            <slot></slot>
        </Link>
        <button
            v-bind="$attrs"
            :type="type"
            :disabled="disabled || loading"
            :class="generatedKind"
            v-else
        >
            <slot></slot>
        </button>
    </template>
</template>
